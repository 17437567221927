require("./bootstrap");
require("dropzone");
bsCustomFileInput = require("bs-custom-file-input");
require("slick-carousel");
require("ekko-lightbox");

require("jquery-ui/ui/widgets/autocomplete.js");

$(function() {
  $(document).on("click", '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
  });

  var url = document.location.toString();
  var anchors = ["stalldaten", "stammdaten"];

  if ($('#pills-tab a[href="#' + anchors.join("-") + '"]').length == 0) {
    anchors = ["stalldaten", "unterbringungsart"];

    if ($('#pills-tab a[href="#' + anchors.join("-") + '"]').length == 0) {
      anchors = ["profil"];
    }
  }

  if ($('#pills-tab a[href="#' + anchors.join("-") + '"]').length > 0) {
    if (url.match("#")) {
      tmp = url.split("#")[1].split("-");
      if (tmp.length != 1 && tmp[1] != "stalldaten") {
        anchors = tmp;
      }

      anchors = tmp;
    }

    var selector = [];
    anchors.forEach((element, index) => {
      selector[index] = element;

      $(
        '.nav-tabs a[href="#' +
          selector.join("-") +
          '"], .nav-pills a[href="#' +
          selector.join("-") +
          '"]'
      ).tab("show");
    });
  }

  $(".nav-tabs a, .nav-pills a").on("click", function(e) {
    var anchor = e.target.hash;
    // if ($(this).data("anchor-prefix"))
    //     anchor = $(this).data("anchor-prefix");
    window.location.hash = anchor;
  });

  $("ul.preis-switch a").on("click", function(e) {
    var show = $(this).data("show");
    var href = $(".btn-jahr").attr("href");

    if (show === "jahr") {
      $(".preis .monat").hide();
      $(".preis .jahr").show();
      var newHref = href.replace("abo=monat", "abo=jahr");
    } else {
      $(".preis .monat").show();
      $(".preis .jahr").hide();
      var newHref = href.replace("abo=jahr", "abo=monat");
    }

    $(".btn-jahr").attr("href", newHref);

    $("ul.preis-switch a").removeClass("active");
    $(this).addClass("active");
  });

  $(".merken").click(function(event){
    event.preventDefault();
    var _token   = $('meta[name="csrf-token"]').attr('content');

    element = $(this);
    $.ajax({
      url: element.data('target'),
      type:"POST",
      data:{
        id:element.data('id'),
        _token: _token
      },
      success:function(response){
        
        if(response.success) {
            if(element.find('i.far.fa-heart').length>0){
                element.find('i.far.fa-heart').removeClass('far').addClass('fas');
            }else{
                element.find('i.fas.fa-heart').removeClass('fas').addClass('far');
            }
        }
      },
     });
  });
});
